import styled from 'styled-components';

const StyledContainer = styled.div`
  height: 48px;
  width: 188px;
  object-fit: contain;
  margin-block-start: 1px;
  margin-block-end: 0;
  margin-inline: auto;
  display: block;
  /*
    We want Reach Router's Link component to handle the onClick event.
    Setting 'pointer-events' to 'none' ensures that this component,
    or its children, don't intercept the clicks.
   */
  pointer-events: none;
`;

export default StyledContainer;
