import styled from 'styled-components';

export const StyledLogo = styled.div`
  margin: 0 auto;
  max-height: 40px;
  min-width: 129px;
  display: block;

  & > svg {
    max-height: inherit;
  }
`;

export const TopServiceModeContainer = styled.div``;
