import React from 'react';

import { OnClick } from '@rbilabs/components-library/build/components/button';
import { Icon } from '@rbilabs/components-library/build/components/icon';
import { Box } from '@rbilabs/components-library/build/components/layout';
import { VisuallyHidden } from '@rbilabs/components-library/build/components/visually-hidden';
import { useIntl } from 'react-intl';

import { ActionButtonSizes, ActionLink } from 'components/action-button';
import { truncateTextWithEllipsis } from 'components/app-header/right-nav/utils';
import defaultConfig from 'components/app-header/styled';
import { useAuthContext } from 'state/auth';
import { routes } from 'utils/routing';

interface IUserAccountButton {
  onClick: OnClick;
}

const UserAccountButton: React.FC<IUserAccountButton> = ({ onClick }) => {
  const { formatMessage } = useIntl();
  const { user } = useAuthContext();

  return (
    <Box margin="0 1rem 0 0">
      <ActionLink
        variant={defaultConfig.linkVariant}
        size={ActionButtonSizes.SMALL}
        transparent
        to={routes.account}
        onClick={onClick}
        data-private
        data-dd-privacy="mask"
        data-testid="my-account-link"
        title={formatMessage({ id: 'myAccount' })}
        onlyIcon
      >
        <Icon icon="profile" color="white" aria-hidden />
        <VisuallyHidden>{formatMessage({ id: 'myAccount' })}</VisuallyHidden>
        <div>{truncateTextWithEllipsis(user?.details?.name)}</div>
      </ActionLink>
    </Box>
  );
};

export default UserAccountButton;
